//mio
import {
  InputBase,
  Button,
  IconButton,
  Box,
  AccordionSummary,
  AccordionActions,
  StepConnector,
  stepConnectorClasses,
  Switch,
  TextField,
  Table,
  Radio,
} from "@mui/material";
import {
  createTheme,
  alpha,
  styled,
  darken,
  lighten,
} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const hoverPrimario = "#6d4271";
const hoverSecundario = "#fbf9fb";
//const mainColor = "#9c5fa2";
const mainColor = "#6C3373";
const mainColorTitulo = "#6c3373";
const colorTexto2 = "#ce8ed3";
const drawerWidth = 287;
const colorTextoClase = "#3E3E3E";
const colorTextoAccordion = "#707071";
const naranja = "#f2af00";

export const getColor = () => {
  let color = {
    hoverPrimario: hoverPrimario,
    hoverSecundario: hoverSecundario,
    mainColor: mainColor,
    mainColorTitulo: mainColorTitulo,
    colorTexto2: colorTexto2,
    drawerWidth: drawerWidth,
    colorTextoClase: colorTextoClase,
    naranja: naranja,
  };
  return color;
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

export const ArgumentappTheme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: mainColor,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      //light: "#000",
      main: "#000",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: "Poppins",
    h4: {
      color: mainColor,
    },
    h5: {
      color: mainColorTitulo,
      fontWeight: 500,
    },
    h6: {
      color: colorTexto2,
      fontWeight: 400,
      fontSize: 16,
    },
    tituloAlert: {
      color: mainColorTitulo,
      fontWeight: 500,
      fontSize: 22,
    },
    body1: {
      fontSize: "13px",
      color: "#232323",
    },
    body4: {
      fontSize: "16px",
      fontFamily: "Poppins",
      color: "#232323",
    },
    body5: {
      fontSize: "18px",
      fontFamily: "Poppins",
      color: "#232323",
    },
    body6: {
      fontSize: "16px",
      fontFamily: "Poppins",
      color: "#232323",
    },
    fontIconButton: {
      fontSize: 16,
      color: mainColorTitulo,
      marginLeft: 10,
      fontWeight: 400,
      fontFamily: "Poppins",
    },
    allVariants: {
      color: "#232323",
    },
  },
  shadows: {
    ...createTheme({}).shadows.map((shadow, i) =>
      i === 1 ? "0 5px 8px 3px rgba(9, 32, 46, 0.06)" : shadow
    ),
  },
  components: {
    CustomInput2: {
      styleOverrides: {
        fullWidth: {
          width: "100%",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: "#232323",
        },
      },
      variants: [
        {
          props: { tipo: "general" },
          style: {
            padding: 30,
            borderRadius: "18px",
          },
        },
        {
          props: { tipo: "respuestasComparar" },
          style: {
            width: "95%",
            boxShadow: "none",
            padding: "20px",
            borderRadius: "25px",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#eee",
            },
            "&.selected": {
              backgroundColor: "#EADEEC",
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "32px",
          padding: "0 30px 0 30px",
          height: "45px",
          boxShadow: "none",
          fontSize: "15px",
          fontWeight: 400,
          textTransform: "none",
        },
        fullWidth: {
          width: "100%",
        },
      },
      variants: [
        {
          props: { variant: "alternativa" },
          style: {
            background: mainColorTitulo,
            width: 400,
            color: "#fff",
            fontWeight: 500,
            height: "80px",
            borderRadius: "16px",
            justifyContent: "flex-start",
            "&:hover": {
              background: hoverPrimario,
            },
          },
        },
        {
          props: { variant: "modalActionLeft" },
          style: {
            color: mainColorTitulo,
            fontWeight: 500,
            height: "80px",
            borderRadius: "0 0 0 18px",
          },
        },
        {
          props: { variant: "modalActionRight" },
          style: {
            color: mainColorTitulo,
            fontWeight: 500,
            height: "80px",
            borderRadius: "0 0 18px 0",
          },
        },
        {
          props: { variant: "modalActionMiddle" },
          style: {
            color: mainColorTitulo,
            fontWeight: 500,
            height: "80px",
            borderRadius: 0,
          },
        },
        {
          props: { variant: "modalActionExtra" },
          style: {
            color: mainColorTitulo,
            borderStyle: "none",
            textDecoration: "underline",
            textUnderlineOffset: "4px",
          },
        },
        {
          props: { size: "small" },
          style: {
            padding: "0 10px 0 10px",
          },
        },
        {
          props: { variant: "contained" },
          style: {
            borderRadius: "32px",
            padding: "0 30px 0 30px",
            height: "45px",
            boxShadow: "none",
            fontSize: "15px",
            fontWeight: 400,
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#124e8b",
              color: "#fff",
              boxShadow: "none",
              borderColor: "transparent",
            },
          },
        },
        {
          props: { variant: "basic_blanco" },
          style: {
            boxShadow: "none",
            color: "#fff",
            backgroundColor: "transparent",
            borderRadius: 0,
            "&:disabled": {
              color: darken("#fff", 0.2),
              backgroundColor: darken(mainColor, 0.2),
            },
          },
        },
        {
          props: { variant: "WhiteStyle" },
          style: {
            boxShadow: "none",
            color: "#6d4271",
            backgroundColor: "transparent",
            borderRadius: 16,
            "&:disabled": {
              color: darken("#fff", 0.2),
              backgroundColor: darken(mainColor, 0.2),
            },
          },
        },
        {
          props: { variant: "WhiteStyleClicked" },
          style: {
            boxShadow: "none",
            color: "#6d4271",
            backgroundColor: "rgba(156,95,162,0.2)",
            borderRadius: 16,
          },
        },
        {
          props: { tipo: "config" },
          style: {
            width: "230px",
            height: "75px",
            fontSize: "18px",
            marginTop: 15,
            marginBottom: 15,
          },
        },
        {
          props: { tipo: "mini" },
          style: {
            width: "86px",
            height: "23px",
            fontSize: "12px",
            fontWeight: 600,
          },
        },
        {
          props: { tipo: "small" },
          style: {
            width: "140px",
            height: "40px",
            fontSize: "12px",
          },
        },
        {
          props: { variant: "contained_blanco" },
          style: {
            backgroundColor: "#fff",
            color: mainColor,
          },
        },
        {
          props: { variant: "contained_gris" },
          style: {
            backgroundColor: "#f5f5f5",
            color: "#555",
            boxShadow: "none",
          },
        },
        {
          props: { variant: "simil_input" },
          style: {
            backgroundColor: "#f5f5f5",
            color: "#555",
            boxShadow: "none",
          },
        },
        {
          props: { variant: "contained_naranja" },
          style: {
            backgroundColor: naranja,
            color: "#fff",
            "&:hover": {
              backgroundColor: darken(naranja, 0.1),
            },
            "&:disabled": {
              backgroundColor: lighten(naranja, 0.7),
            },
          },
        },
        {
          props: { variant: "botonDelete" },
          style: {
            backgroundColor: "red",
            color: "white",
            borderColor: "red",
            borderWidth: 1,
            borderStyle: "solid",
            padding: 0,
            fontSize: 14,
            ":hover": {
              color: "red",
              borderColor: "red",
              borderWidth: 1,
              borderStyle: "solid",
            },
          },
        },
        {
          props: { variant: "Gris_negative" },
          style: {
            backgroundColor: "#F5F5F5",
            color: "#555",
          },
        },
        {
          props: { variant: "white_Button" },
          style: {
            backgroundColor: "#fff",
            color: "#555",
          },
        },
        {
          props: { tipo: "respuesta" },
          style: {
            backgroundColor: mainColorTitulo,
            paddingLeft: 60,
            paddingRight: 60,
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "rgba(156,95,162,0.2)",
          },
        },
      },
      variants: [
        {
          props: { variant: "IconButtonText" },
          style: {
            borderRadius: 12,
            width: 140,
            justifyContent: "flex-start",
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: "80px",
          height: "80px",
        },
      },
      variants: [
        {
          props: { tipo: "menu-perfil" },
          style: {
            // position: "absolute",
            // left: "30px",
            // top: "25px",
            width: 60,
            height: 60,
            borderRadius: 16,
          },
        },
      ],
    },
    MuiTypography: {
      root: {
        fontFamily: "Poppins",
      },
      variants: [
        {
          props: { variant: "titulo" },
          style: {
            color: mainColorTitulo,
            fontSize: "24px",
            fontWeight: 600,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "textoHome" },
          style: {
            color: "#2F2F2F",
            fontSize: "35px",
            fontWeight: "700 !important",
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "spanDiapo" },
          style: {
            color: "#A6A6A6",
            fontSize: "14px",
            fontWeight: 400,
            fontFamily: "Poppins",
            lineHeight: "18px",
            letterSpacing: "-0.3px",
          },
        },
        {
          props: { variant: "spanDiapoTitle" },
          style: {
            color: "#232323",
            fontSize: "15px",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "titulo_2" },
          style: {
            color: mainColorTitulo,
            fontSize: "30px",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "titulo_2_claro" },
          style: {
            color: mainColor,
            fontSize: "30px",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "titulo_3" },
          style: {
            color: mainColorTitulo,
            fontSize: "25px",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "titulo_3_top" },
          style: {
            color: mainColorTitulo,
            fontSize: "21px",
            fontWeight: 500,
            fontFamily: "Poppins",
            marginLeft: 30,
          },
        },
        {
          props: { variant: "titulo_5" },
          style: {
            color: mainColor,
            fontSize: "18px",
            fontWeight: 500,
            fontFamily: "Poppins",
            padding: 0,
            margin: 0,
          },
        },
        {
          props: { variant: "titulo_3_negro" },
          style: {
            color: "#232323",
            fontSize: "25px",
            fontWeight: 600,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "titulo_3_claro" },
          style: {
            color: mainColor,
            fontSize: "25px",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "titulo_3_oscuro" },
          style: {
            color: mainColorTitulo,
            fontSize: "25px",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "titulo_4" },
          style: {
            color: "#1C1C1C",
            fontSize: "18px",
            fontWeight: 600,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "titulo_4_negro" },
          style: {
            color: "#3E3E3E",
            fontSize: "18px",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "titulo_4_negroclaro_bold" },
          style: {
            color: "#707071",
            fontSize: "18px",
            fontWeight: 800,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "titulo_4_negro_bold" },
          style: {
            color: "#3E3E3E",
            fontSize: "18px",
            fontWeight: 600,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "titulo_4_claro" },
          style: {
            color: mainColor,
            fontSize: "18px",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "titulo_5_negro" },
          style: {
            color: "#232323",
            fontSize: "15px",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "titulo_5_negro_bold" },
          style: {
            color: "#3E3E3E",
            fontSize: "15px",
            fontWeight: 700,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "tituloClase" },
          style: {
            color: mainColorTitulo,
            fontSize: "26px",
            fontWeight: 600,
          },
        },
        {
          props: { variant: "tituloActividadACTONLINE" },
          style: {
            color: mainColorTitulo,
            fontSize: "18px",
            fontWeight: 500,
          },
        },
        {
          props: { variant: "textoClase" },
          style: {
            color: "#3E3E3E",
            fontSize: "18px",
            fontWeight: 500,
          },
        },
        {
          props: { variant: "textoForm" },
          style: {
            color: colorTextoClase,
            fontSize: "16px",
            fontWeight: 500,
            marginBottom: 5,
          },
        },
        {
          props: { variant: "infoTextoRosa" },
          style: {
            color: mainColor,
            fontSize: "12px",
            fontWeight: 300,
          },
        },
        {
          props: { variant: "TituloActividadClase" },
          style: {
            color: colorTextoClase,
            fontSize: "18px",
            fontWeight: 500,
          },
        },
        {
          props: { variant: "nombreActividadAccordion" },
          style: {
            color: "#232323",
            fontSize: "16px",
            fontWeight: 500,
            textAlign: "left",
            padding: 10,
          },
        },
        {
          props: { variant: "RosaClaro_16" },
          style: {
            color: mainColor,
            fontSize: "16px",
            fontWeight: 500,
            textAlign: "left",
            padding: 10,
          },
        },
        {
          props: { variant: "RosaClaro_16_light" },
          style: {
            color: mainColor,
            fontSize: "16px",
            fontWeight: 400,
          },
        },
        {
          props: { variant: "RosaClaro_12" },
          style: {
            color: mainColor,
            fontSize: "12px",
            fontWeight: 500,
          },
        },
        {
          props: { variant: "negro_7" },
          style: {
            color: "#3E3E3E",
            fontSize: "6.3pt",
            fontWeight: 500,
          },
        },
        {
          props: { variant: "TextInformativo_15px_300" },
          style: {
            color: colorTextoClase,
            fontSize: "15px",
            fontWeight: 300,
            textAlign: "center",
            maxWidth: "430px",
          },
        },
        {
          props: { variant: "unidadStyle" },
          style: {
            color: mainColor,
            fontSize: "16px",
            fontWeight: 600,
            textAlign: "left",
            hyphens: "auto",
          },
        },
        {
          props: { variant: "nameUnidad" },
          style: {
            color: "#232323",
            fontSize: "14px",
            fontWeight: "normal",
            textAlign: "left",
            display: "-webkit-box",
            WebkitLineClamp: "4",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            hyphens: "auto",
          },
        },
        {
          props: { variant: "numModulos" },
          style: {
            color: "#A6A6A6",
            fontSize: "12px",
            fontWeight: "normal",
            textAlign: "left",
            display: "-webkit-box",
            WebkitLineClamp: "4",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            hyphens: "auto",
          },
        },
        {
          props: { variant: "nameUnidadDosLineas" },
          style: {
            color: "#3E3E3E",
            fontSize: "12px",
            fontWeight: "normal",
            textAlign: "left",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            hyphens: "auto",
          },
        },
        {
          props: { variant: "cardCodigo" },
          style: {
            color: "#999",
            fontSize: "14px",
            fontWeight: 500,
            textAlign: "left",
            display: "-webkit-box",
            WebkitLineClamp: "5",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          },
        },
        {
          props: { variant: "titulo_pregunta" },
          style: {
            color: "#707070",
            fontSize: "25px",
            fontWeight: 800,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "texto_info_card" },
          style: {
            color: "#999",
            fontSize: "13pt",
            fontWeight: 400,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "pizarra_codigo_actividad" },
          style: {
            color: mainColorTitulo,
            fontSize: "45pt",
            fontWeight: 700,
            fontFamily: "Poppins",
          },
        },
        {
          props: { variant: "link" },
          style: {
            color: mainColor,
            fontSize: "10.5pt",
            fontWeight: 700,
            fontFamily: "Poppins",
            textDecoration: "underline",
          },
        },
      ],
    },
    LoginTopButton: {
      variants: [
        {
          props: { selected: "true" },
          style: {
            color: "#3E3E3E",
            background: "#fff",
            ":hover": {
              background: hoverSecundario,
            },
          },
        },
      ],
    },
    CustomIconButton: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            background: "transparent",
            color: mainColor,
            fontSize: "1.3em",
            boxShadow: "none",
            ":hover": {
              background: "#f0f0f0",
            },
            "& .bi": {
              width: "1em",
              height: "1em",
            },
          },
        },
        {
          props: { size: "large" },
          style: {
            fontSize: "1.8em",
          },
        },
      ],
    },
    BoxModalArgumentapp: {
      variants: [
        {
          props: { variant: "acciones" },
          style: {
            background: "#000",
            minWidth: 400,
            maxWidth: 500,
            minHeight: 250,
          },
        },
      ],
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 0,
          backgroundColor: "#faf8ff",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          width: "auto",
          borderRadius: "18px",
          background: "#fff",
        },
      },
      variants: [
        {
          props: { variant: "PopOverActividadOnline" },
          style: {
            ".MuiPopover-paper": {
              borderRadius: "32px",
              background: mainColor,
            },
          },
        },
        {
          props: { variant: "tipoRespuesta" },
          style: {
            ".MuiPopover-paper": {
              background: mainColor,
              width: "180px",
            },
          },
        },
      ],
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiFormControlLabel: {
      variants: [
        {
          props: { variant: "ControlLabelAlternativas" },
          style: {
            ".MuiFormControlLabel-label": {
              color: "#fff",
            },
            minWidth: 125,
            maxWidth: 210,
          },
        },
      ],
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: 80,
        },
      },
      variants: [
        {
          props: { variant: "respuestas" },
          style: {
            color: "#707070",
            //height: 150,
            flexDirection: "column",
          },
        },
      ],
    },
    MuiAppBar: {
      variants: [
        {
          props: { variant: "respuestas" },
          style: {
            //background: "transparent",
            borderRadius: "0 0 30px 30px",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
          },
        },
        {
          props: { variant: "diapo-respuestas" },
          style: {
            //background: "transparent",
            borderRadius: "0",
            boxShadow: "none",
          },
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          color: "black",
          fontWeight: 600,
        },
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { variant: "argumentapp" },
          style: {
            ".MuiAlert-icon": {
              color: "#9c5fa2",
              width: "21px",
              height: "21px",
            },
            background: "rgba(156, 95, 162, 0.12)",
            borderRadius: "16px",
            minHeight: "42px",
            fontSize: "13px",
            color: "black",
          },
        },
      ],
    },
  },
});

export const CustomInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: "16pt",
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#f3f3f3" : "#2b2b2b",
    border: 0,
    fontSize: 13,
    width: "350px",
    padding: "15px 17px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.1rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const CustomInputLogin = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: "16pt",
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#f3f3f3" : "#2b2b2b",
    border: 0,
    fontSize: 13,
    width: "350px",
    padding: "15px 17px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.1rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
}));

export const CustomInput2 = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 24,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#2b2b2b",
    border: 1,
    borderStyle: "solid",
    borderColor: "#555",
    fontSize: 13,
    color: "#555",
    fontWeight: 300,
    padding: "12px 14px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.15)} 0 0 0 0.05rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const CustomInputLogin2 = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    fontSize: 13,
    color: "#3E3E3E",
    fontWeight: 500,
    padding: "14px 18px 14px 18px",
    width: 300,
    border: "1px solid #cfcfcf",
    borderRadius: 16,
    "&:focus": {
      borderColor: mainColor,
    },
  },
}));

export const InputPassWord = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    fontSize: 13,
    color: "#3E3E3E",
    fontWeight: 500,
    padding: "14px 18px 14px 18px",
    borderRadius: "16px 0 0 16px",
    width: "75%",
  },
  borderRadius: "16px",
  border: "1px solid #cfcfcf",
  "&:focus": {
    borderColor: mainColor,
  },
}));

export const ButtonSimple = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  boxShadow: "none",
  borderBottom: "2px solid #333",
  fontWeight: 500,
  color: "#555",
  padding: 0,
}));

export const ArgumentappBox = styled(Box)(({ theme }) => ({
  height: "fit-content",
  background: "#fff",
}));

export const CustomIconButton = styled(IconButton, {
  name: "CustomIconButton",
  slot: "Root",
})(({ theme }) => ({
  background: theme.palette.primary.main,
  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
  color: theme.palette.primary.contrastText,
  ":hover": {
    background: hoverPrimario,
  },
}));

export const LoginTopButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "selected" && prop !== "sx",
  name: "LoginTopButton",
  slot: "Root",
})(({ theme }) => ({
  borderRadius: "25px 25px 0 0",
  backgroundColor: theme.palette.primary.main,
  boxShadow: "none",
  fontWeight: 500,
  fontSize: "15px",
  textAlign: "right",
  color: theme.palette.primary.contrastText,
  width: 250,
  height: 80,
  ":hover": {
    background: hoverPrimario,
  },
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export const AccordionActividadSummary = styled(AccordionSummary)(
  ({ theme }) => ({
    "& .MuiAccordionSummary-content": {
      margin: 0,
      cursor: "default",
      padding: 0,
    },
    "& .MuiAccordionSummary-root": {
      padding: 0,
      cursor: "help",
      margin: 0,
    },
    "& .Mui-expanded": {
      margin: "0px",
    },
    margin: 0,
    cursor: "default",
  })
);
export const AccordionActividadActions = styled(AccordionActions)(
  ({ theme }) => ({
    width: "100%",
    height: "100%",
    justifyContent: "start",
    padding: 6,
    cursor: "pointer",
  })
);

export const BoxModalArgumentapp = styled(Box)((theme) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: 0,
  // maxHeight: 1050,
  maxHeight: "80vh" || 1050,
  minHeight: 350,
  backgroundColor: "#fff",
  borderRadius: "18px",
  minWidth: 500,
  maxWidth: 1600,
  overflowY: "auto",
}));

export const InfoArgumentapp = () => {
  return (
    <span
      className="material-symbols-outlined"
      style={{ color: mainColor, fontSize: "5.5rem", marginBottom: 25 }}
    >
      error
    </span>
  );
};

export const SuccessArgumentapp = () => {
  return (
    <CheckCircleOutlineOutlinedIcon
      sx={{ color: mainColor, fontSize: "4rem", marginBottom: "25px" }}
    />
  );
};
export const ErrorArgumentapp = () => {
  return (
    <CancelOutlinedIcon
      sx={{ color: mainColor, fontSize: "4rem", marginBottom: "25px" }}
    />
  );
};

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.vertical}`]: {
    top: 0,
    left: "calc(-90% + 16px)",
    right: "calc(90% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#9c5fa2",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
  },
}));

export const AntSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#ce8ed3" : "#ce8ed3",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#ce8ed3",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#6c3373" : "#6c3373",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const TablaArgumentapp = styled(Table)(() => ({
  borderCollapse: "separate",
  borderSpacing: "0 15px",
  "& .MuiTableCell-situacion": {
    background: mainColor,
    width: 200,
    color: "#fff",
    "& .MuiInputBase-input": {
      color: "#fff",
      width: "100%",
    },
  },
  "& .MuiTableCell-root": {
    border: 0,
  },
  "& .MuiTableCell-head": {
    color: mainColor,
    "& .MuiInputBase-input": {
      color: mainColor,
    },
  },
  "& .MuiTableCell-body": {
    borderBottom: "2px solid",
    borderRight: "2px solid",
    borderLeft: 0,
    borderTop: "2px solid",
    borderColor: mainColor,
    color: mainColor,
  },
  "& .MuiTableRow-root": {
    "& td:first-child": {
      borderRadius: "18px 0 0 18px",
    },
    "& td:last-child": {
      borderRadius: "0 18px 18px 0",
    },
  },
  "& .MuiTableRow-head": {
    "& th": {
      border: 0,
      fontWeight: 600,
    },
  },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
  "&.MuiRadio-root": {
    padding: 0,
    marginRight: theme.spacing(1),
  },
  "&.MuiRadio-colorSecondary.Mui-checked": {
    color: "#5ac9de",
  },
  "&.MuiRadio-colorSecondary.Mui-checked:hover": {
    backgroundColor: "rgba(90, 201, 222, 0.08)",
  },
  "&.MuiRadio-colorSecondary:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
}));
